@tailwind base;
@tailwind components;
@tailwind utilities;
/* 406 */


@layer components {
    .hoverEffect3 {
        @apply cursor-pointer hover:bg-gray-200 active:bg-blue-200 rounded-full transition duration-200 ease-out
    }
    .hoverEffect2 {
        @apply cursor-pointer hover:bg-gray-200 rounded-full transition duration-500 ease-out
    }
    .hoverEffect {
        @apply cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 rounded-full transition duration-500 ease-out p-[2px]
    }
    .back-color {
        @apply bg-[#f3f3f9]
    }
    .card {
        @apply shadow-md bg-white rounded-md p-4
    }
    .inp {
        @apply block w-full h-9 px-3 text-sm font-normal leading-5 text-gray-700 bg-gray-100 border border-gray-300 rounded-md
    }
    .but {
        @apply rounded-md px-2.5 h-9  whitespace-nowrap
    }
    .but-basic {
        @apply text-white bg-[#405189] hover:bg-[#39487b] active:bg-[#33406d] disabled:bg-[#9fa8c4]
    }
    .but-basic-color {
        @apply text-white disabled:bg-[#9fa8c4] font-semibold
    }
    .but-basic-red {
        @apply text-white bg-red-600 hover:bg-red-700 active:bg-red-800 disabled:bg-[#9fa8c4]
    }
    .but-basic-emp {
        @apply text-[#405189] bg-white border border-[#405189] hover:but-basic
    }
    .but-basic-emp-green {
        @apply text-white bg-green-500 border border-green-500 hover:bg-green-600 active:bg-green-700 hover:text-white
    }
    .but-basic-emp-emp {
        @apply text-gray-600 bg-white hover:bg-red-500 active:bg-red-600 hover:text-white
    }
    .but-icon-red {
        @apply rounded-full bg-white text-red-600 px-1
    }
    .but-file {
        @apply but but-basic border-0
    }

    .table-master th  {
        @apply  bg-[#536294] px-3 py-2 text-start whitespace-nowrap text-white font-medium
    }
    .table-master td  {
        @apply  px-3 py-3 border-y whitespace-nowrap max-w-[250px] overflow-hidden truncate
        /*  */
    }
    input:focus-visible {
        outline: none;
    }
    .buble {
        @apply inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded
    }
    .grow {
        @apply transition ease-in-out  hover:scale-105 duration-300
    }
    .main-height {
        @apply min-h-[350px] h-screen
    }
}

/* @media screen and (max-height: 350px) {
    .sett_but_menu {
        display: none;
    }
  }
   */


/* .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
} */


/* .select__input-container {
    display: none !important;
} */